import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./routes/routes"; // Assicurati che il percorso sia corretto
import { ToastContainer } from 'react-toastify';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <AppRoutes />
      </Router>
        <ToastContainer />
    </div>
  );
}

export default App;
