import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import Badge from "@mui/material/Badge";
import {styled} from "@mui/system";
import {useLocation} from 'react-router-dom';

const drawerWidth = 240; // Larghezza del Drawer

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const AppBarComponent = ({open, toggleDrawer}) => {
    const location = useLocation();
    console.log(location);
    const pathName = location.pathname.split('/').pop(); // Ottieni l'ultima parte del percorso
    // Mappa dei nomi delle pagine
    const pageNames = {
        home: 'Home',
        cookbook: 'CookBook',
        about: 'About',
        dashboard: 'Dashboard'
    };
    // Determina il titolo della pagina in base al percorso
    const titlePage = pageNames[pathName] || 'Unknown Page';


    return (
        <AppBar position="absolute" open={open}>
            <Toolbar
                sx={{
                    pr: '24px', // mantieni il padding destro quando il drawer è chiuso
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && {display: 'none'}),
                    }}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    {titlePage}
                </Typography>
                {/*<IconButton color="inherit">*/}
                {/*    <Badge badgeContent={4} color="secondary">*/}
                {/*        <NotificationsIcon/>*/}
                {/*    </Badge>*/}
                {/*</IconButton>*/}
            </Toolbar>
        </AppBar>
    );
};

export default AppBarComponent;
