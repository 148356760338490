import React, {useState, useEffect} from 'react';
import CardMedia from '@mui/material/CardMedia';
import {fetchImageGet} from "../../utils/API/apiService";


const ImageCard = ({photoId,sx}) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await fetchImageGet(`store/file/${photoId ? photoId : 3}`);
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response]));
                setImageSrc(url);
            } catch (error) {
                console.error('Image download failed', error);
            }
        };

        if (photoId) {
            fetchImage();
        }
    }, [photoId]);

    return (
        <>
            {imageSrc ? (
                <CardMedia
                    component="img"
                    sx={sx}
                    // sx={{width: '40%', display: {sm: 'block'}, paddingBottom: 2,paddingTop:2}}
                    image={imageSrc}
                    alt={'Image'}
                    onError={(e) => {
                        e.target.onerror = null; // Previene il loop infinito se l'immagine di fallback non esiste
                        // e.target.src = 'fallback-image-url'; // URL dell'immagine di fallback
                    }}
                />
            ) : (
                <div>Loading...</div> // Placeholder mentre l'immagine viene caricata
            )}
        </>
    );
};

export default ImageCard;
