// IngredientsList.js
import React, {useState} from 'react';
import {
    Button, List, ListItem, ListItemSecondaryAction,
    IconButton
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import IngredientModal from './IngredientModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from "../general/ConfirmationDialog";
import {fetchDataDelete, fetchDataPost} from "../../utils/API/apiService";

const IngredientsList = ({
                             ingredients,
                             setFormData,
                             handleViewAddIngredientButton,
                             editMode = false
                         }) => {


    const [openModal, setOpenModal] = useState(false);

    const [ingredientSelected, setIngredientSelected] = useState({
        id: null,
        name: "",
        unitMeasure: "",
        quantity: null
    });
    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, ingredientId: null});
    const handleOpenDialogDeleteIngredient = (id) => {
        setOpenConfirmDialog({open: true, ingredientId: id});
    };
    const handleCancelDelete = () => {
        setOpenConfirmDialog({open: false, ingredientId: null});
    };
    const handleConfirmDelete = () => {

        fetchDataDelete(`recipe/ingredientsRecipe/delete/${openConfirmDialog.ingredientId}`)
            .then( () => {
                setFormData((prevData) => ({
                    ...prevData,
                    ingredients: prevData.ingredients.filter((ingredient) => ingredient.id !== openConfirmDialog.ingredientId)
                }));
                setOpenConfirmDialog({open: false, ingredientId: null});
                console.log(`Cancellazione dell'ingrediente con id ${openConfirmDialog.ingredientId}`);
            }
        );

    };

    const handleAddIngredient = () => {

        setFormData((prevData) => {
            const existingRecipe = prevData.id != null ? prevData.id : false;
            if(ingredientSelected.unitMeasure==='u'){
                ingredientSelected.prefix='';
            }
            if (existingRecipe) {
                const ingredientsRecipeData = {
                    recipeId:existingRecipe,
                    quantity: ingredientSelected.quantity,
                    metricPrefix: ingredientSelected.prefix ? ingredientSelected.prefix : " ",
                    ingredient: {
                        id: ingredientSelected.id,
                        name: ingredientSelected.name,
                        unitMeasure: ingredientSelected.unitMeasure,
                    }
                };
                console.log(ingredientSelected);
                console.log(ingredientsRecipeData);
                const result = fetchDataPost('recipe/ingredientsRecipe/addIngredientsRecipe', ingredientsRecipeData);

                console.log("la ricetta esiste", existingRecipe);
            } else {
                console.log("la ricetta non esiste");
            }


            return {
                ...prevData,
                ingredients: [
                    ...prevData.ingredients,
                    {
                        id: ingredientSelected.id,
                        name: ingredientSelected.name,
                        quantity: ingredientSelected.quantity,
                        unitMeasure: ingredientSelected.unitMeasure,
                        prefix: ingredientSelected.prefix
                    }
                ]
            };
        });
        setIngredientSelected({
            id: null,
            name: "",
            unitMeasure: "",
            quantity: null,
            prefix: ""
        })
    };


    return (
        <div>
            {handleViewAddIngredientButton && (
                <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                    Aggiungi ingrediente
                </Button>
            )}

            <List>
                {ingredients.map((ingredient, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={ingredient.name}
                            secondary={`${ingredient.quantity} ${ingredient.prefix}${ingredient.unitMeasure!=='u'? ingredient.unitMeasure : ''}`}
                        />
                        {editMode && (
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete"
                                            onClick={() => handleOpenDialogDeleteIngredient(ingredient.id)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                ))}
            </List>
            <IngredientModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                ingredientSelected={ingredientSelected}
                setIngredientSelected={setIngredientSelected}
                handleAddIngredient={handleAddIngredient}
            />
            <ConfirmationDialog
                open={openConfirmDialog.open}
                title="Conferma eliminazione"
                message="Sei sicuro di voler eliminare questo ingrediente?"
                confirmButtonText="Conferma"
                cancelButtonText="Annulla"
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </div>
    );
};

export default IngredientsList;
